import { CapacitorHttp } from "@capacitor/core";
import { Device } from "@capacitor/device";

const baseURL = "https://wallpaper-api.noapi.space/api";

const api = {
  async defaultOptions() {
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    return {
      headers: {
        accept: "application/json",
        authorization: `Bearer ${deviceId.identifier}`,
        "x-device-info": JSON.stringify(deviceInfo),
        "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  },
  async connect(params) {
    return CapacitorHttp.get({
      ...(await this.defaultOptions()),
      url: `${baseURL}/connect`,
      params: { ...params },
    });
  },
};
export default api;
