<template>
  <div class="media-wrapper" @click="showMediaLayer = !showMediaLayer">
    <div
      class="close"
      @click="activeMedia = null"
      :style="`top: ${safeAreaInsets.top}px`"
    >
      <img src="@/assets/images/icons/back.svg" alt="" />
    </div>
    <div class="media">
      <img :src="media.file_url" alt="" />
    </div>
    <div class="actions">
      <div class="actions-wrapper">
        <!-- <button class="button like" type="button" @click="like()">
          <img src="@/assets/images/icons/heart.png" alt="" />
        </button> -->
        <button
          class="button download primary"
          type="button"
          @click="download()"
        >
          Set Wallpaper
        </button>
      </div>
    </div>
    <MediaLayer v-if="showMediaLayer" />
  </div>
</template>

<script>
import { Media } from "@capacitor-community/media";
import { useIndexStore } from "@/store";
import MediaLayer from "./MediaLayer.vue";

export default {
  name: "WMedia",
  components: {
    MediaLayer,
  },
  data() {
    return {
      showMediaLayer: true,
    };
  },
  computed: {
    media() {
      return useIndexStore().activeMedia;
    },
  },
  mounted() {},
  methods: {
    async download() {
      // window.location.href = "App-prefs:root=Wallpaper";
      // window.location.href = "prefs:root=WIFI";
      this.$gtag.event("download", {
        event_category: "general",
        event_label: "download_wallpaper",
        value: 1,
      });
      this.isAppLoading = true;
      await Media.savePhoto({
        path: this.media.file_url,
      })
        .then(() => {
          alert("Saved to gallery.");
        })
        .finally(() => {
          this.isAppLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  .close {
    position: absolute;
    left: 24px;
    background-color: #525252;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .media {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .actions {
    position: absolute;
    width: 100%;
    height: 25vh;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    padding-bottom: 40px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 80%
    );
    &-wrapper {
      margin: 0 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        &.download {
          width: 100%;
          border-radius: $radius-sm;
        }
        &.like {
          width: 20%;
          background-color: transparent;
          img {
            width: 40px;
          }
        }
      }
    }
  }
}
</style>
