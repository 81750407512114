<template>
  <div class="paywall">
    <img class="bg" src="@/assets/images/paywall/1.png" alt="" />
    <div class="close" :style="[`top: calc(${safeAreaInsets.top}px + 12px)`]">
      <img
        src="@/assets/images/icons/close.png"
        alt=""
        @click="showPaywall = false"
      />
      <div class="restore">Restore</div>
    </div>
    <div class="paywall-content">
      {{ products }}
      <div class="title-wrapper">
        <div class="title">
          <span class="color">Pro</span>
          <span>Version</span>
        </div>
        <div class="description">
          <span>
            {{ $t(" Unlock all content. Daily content updates. Remove ads.") }}
          </span>
        </div>
      </div>

      <div class="plans" :class="{ 'show-all': showAll }">
        <div class="item active">
          <div class="left">
            <div class="checkbox"></div>
            <div class="info">
              <span class="item-name">Weekly</span>
              <span class="item-price">0.99$ / week</span>
            </div>
          </div>
          <div class="right"></div>
        </div>

        <div class="item">
          <div class="left">
            <div class="checkbox"></div>
            <div class="info">
              <span class="item-name">Annualy</span>
              <span class="item-price">7,99$ / year</span>
            </div>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="show-more" @click="showAll = true" v-if="!showAll">
        <span> {{ $t("View all plans") }}</span>
        <img src="@/assets/images/icons/light/chevron-bottom.png" alt="" />
      </div>
      <button type="submit" class="button primary cta">Continue</button>
      <div class="infos">
        <router-link :to="{ name: 'terms-and-use' }">
          {{ $t("Terms of Use") }}
        </router-link>
        <router-link :to="{ name: 'privacy-and-policy' }">
          {{ $t("Privacy & Policy") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import { useIndexStore } from "@/store";
export default {
  name: "WPaywall",
  data() {
    return {
      showAll: true,
    };
  },
  mounted() {
    // useIndexStore().getProducts();
  },
};
</script>

<style lang="scss" scoped>
.paywall {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $dark;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: $radius-xl;
  border-top-right-radius: $radius-xl;
  .bg {
    border-top-left-radius: $radius-xl;
    border-top-right-radius: $radius-xl;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 64%;
    object-fit: cover;
  }
  .close {
    position: absolute;
    left: $gap-xl;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    img {
      border-radius: 100px;
      width: 32px;
      background-color: $light;
    }
    .restore {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &-content {
    margin: 0 $gap-xl;
    margin-bottom: $gap-xl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .title-wrapper {
      // background-color: #00000090;
      // border-radius: 12px;
      // padding: 12px 24px;
      .title {
        font-size: 32px;
        font-weight: 700;
        color: $white;
        margin-bottom: $gap-md;
        display: flex;
        justify-content: center;
        .color {
          color: $primary;
          margin-right: 6px;
        }
      }
      .description {
        font-size: 16px;
        color: $white;
        text-align: center;
        margin-bottom: $gap-xl;
        line-height: 20px;
        font-weight: 500;
      }
    }

    .plans {
      width: 100%;
      height: 77px;
      overflow: hidden;
      &.show-all {
        height: auto !important;
        // transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $gap-sm;
        background-color: $dark-3;
        padding: $gap-md;
        border-radius: $radius-md;
        border: 3px solid transparent;
        .left {
          display: flex;
          align-items: center;
          .checkbox {
            width: 20px;
            height: 20px;
            border: 2px solid $light;
            border-radius: 100px;
            margin-right: $gap-sm;
          }
          .info {
            display: flex;
            flex-direction: column;
            .item-name {
              font-size: 13px;
              font-weight: 700;
              color: $light;
              margin-bottom: 8px;
            }
            .item-price {
              font-size: 12px;
              font-weight: 500;
              color: $light;
            }
          }
        }
        &.active {
          border-color: $blue;
          .left {
            .checkbox {
              background-color: $blue;
            }
          }
        }
      }
    }

    .show-more {
      margin: 16px 0;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
      }
      img {
        width: 16px;
        margin-left: 4px;
      }
    }
    .button.cta {
      width: 100%;

      margin-bottom: $gap-xl;
    }
    .infos {
      display: flex;
      justify-content: space-between;
      width: 75%;
      a {
        font-size: 12px;
        font-weight: 500;
        color: $light-2;
        text-decoration: underline;
      }
    }
  }
}
</style>
