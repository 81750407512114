<template>
  <Transition>
    <div class="page full-h" id="page">
      <router-view :style="[`padding-top: ${safeAreaInsets.top}px`]" />
    </div>
  </Transition>
  <loading v-if="isAppLoading" />
  <!-- <navigation v-if="!$route.meta.hideNavigation" /> -->
  <side-menu v-if="showSideMenu" />
  <media v-if="activeMedia" />
  <paywall v-if="showPaywall" />
</template>

<script>
import { useIndexStore } from "./store";

import Loading from "@/components/Loading.vue";
import Media from "@/components/Media.vue";
import Paywall from "@/components/Paywall.vue";
import SideMenu from "@/components/SideMenu.vue";
import { SafeArea } from "capacitor-plugin-safe-area";

export default {
  components: {
    Loading,
    Paywall,
    SideMenu,
    Media,
  },
  async mounted() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (insets.top === 0) {
        insets.top = 0;
      }
      useIndexStore().safeAreaInsets = insets;
    });
  },
};
</script>

<style lang="scss">
.page {
  position: relative;
}
</style>
