<template>
  <div class="media-layer">
    <ul class="media-layer-list">
      <li v-for="item in items" :key="item.id">
        <img
          :src="require(`@/assets/images/set-wallpaper/${item.image}.png`)"
          alt=""
        />
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          name: "Photos",
          image: 1,
        },
        {
          id: 2,
          name: "Blog",
          image: 2,
        },
        {
          id: 3,
          name: "Videos",
          image: 3,
        },

        {
          id: 4,
          name: "Music",
          image: 4,
        },
        {
          id: 5,
          name: "Meet",
          image: 5,
        },
        {
          id: 6,
          name: "Game",
          image: 6,
        },
        {
          id: 7,
          name: "E-mail",
          image: 7,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.media-layer {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  margin-top: 60px;
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 3vw;
    grid-row-gap: 32px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 12px;
        width: 64px;
      }
      span {
        text-align: center;
        color: $white;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
</style>
