import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/",
    name: "onboarding",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */ "../views/OnboardingView.vue"
      ),
  },
  {
    path: "/tag/:id/:name",
    name: "tag",
    component: () =>
      import(/* webpackChunkName: "tag" */ "../views/TagDetailView.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/SearchView.vue"),
  },
  {
    path: "/terms-and-use",
    name: "terms-and-use",
    component: () =>
      import(
        /* webpackChunkName: "terms-and-use" */ "../views/TermsAndUseView.vue"
      ),
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/privacy-and-policy",
    name: "privacy-and-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-and-policy" */ "../views/PrivacyAndPolicyView.vue"
      ),
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/copyright",
    name: "copyright",
    component: () =>
      import(/* webpackChunkName: "copyright" */ "../views/CopyrightView.vue"),
    meta: {
      hideNavigation: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
