import api from "@/utils/api";
import { pocketbase } from "@/utils/pocketBaseService";
import { defineStore } from "pinia";
import { Purchases } from "@revenuecat/purchases-capacitor";

// import router from "../router/index";
// import { app } from "@/main";

export const useIndexStore = defineStore("useIndexStore", {
  state: () => ({
    baseURL: "https://wallpaper-api.noapi.space",
    appName: "WaTrack",
    deviceInfo: "sfb-dev",
    userInfo: null,
    activeMedia: null,
    safeAreaInsets: 0,
    isAppLoading: false,
    showPaywall: false,
    tags: [],
    products: "furkan",
  }),
  getters: {
    isProd: () => {
      return false;
    },
    requestHeader: (state) => {
      return {
        platform: state.deviceInfo.platform.toLowerCase(),
        device_id: state.deviceInfo.device_id,
      };
    },
  },
  actions: {
    async connect() {
      this.isAppLoading = true;
      return await api
        .connect()
        .then((res) => {
          this.user = res.data.user;
        })
        .finally(() => {
          this.isAppLoading = false;
        });
    },

    async getAllTags() {
      return await pocketbase
        .collection("tags")
        .getList()
        .then((res) => {
          this.tags = res.items;
          return res;
        });
    },
    async getWallpapersByTag(id) {
      return await pocketbase
        .collection("wallpapers")
        .getList(1, 50, {
          filter: `tags ~ '${id}'`,
        })
        .then((res) => {
          return res;
        });
    },
    async getProducts() {
      alert("hi");
      try {
        const offerings = await Purchases.getOfferings();
        this.products = offerings;
      } catch (error) {
        // Handle error
        this.products = error;
      }
    },
  },
});
