import App from "./App.vue";
import * as Vue from "vue";
import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor";
import "./registerServiceWorker";
import { createPinia } from "pinia";
import i18n from "@/utils/i18n";
import VueLazyLoad from "vue3-lazyload";
import mixins from "../src/config/mixins";
import router from "./router";
import "@/assets/scss/main.scss";
import infiniteScroll from "vue-infinite-scroll";
import { useIndexStore } from "@/store";
import VueGtag from "vue-gtag";

const configure = async () => {
  await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
  await Purchases.configure({
    apiKey: "appl_UpUoGJcYeYKfqeGmxaPPEkWFJIe",
    appUserID: "my_app_user_id", // Optional
  });
};

export const app = Vue.createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(i18n);
app.use(VueLazyLoad);
app.use(infiniteScroll);
app.mixin(mixins);
app.use(VueGtag, { config: { id: "G-X9CPGB2N1K" } }, router);

app.use(router);
router.isReady().then(() => {
  app.mount("#app");
  configure().then(() => {});
});

useIndexStore().connect();
